/**
 * @name: record
 * @author: LJJ
 * @date: 2023-09-04 17:16
 * @description：埋点用的接口
 * @update: 2023-09-04 17:16
 */
import request from "../axios";
import {closeH5} from "@/util/Token";

export const _addActivityLog = (data,success)=>{
    request({
        method: 'post',
        url: '/land/activityLog',
        data
    }).then(res=>{
        if (res.code == 200){
            return success(res.data);
        }else if (res.code == 401 || res.code == 403) {
            closeH5();
        }
    }).catch(err=>{

    })
}
