/**
 * Create by 14059
 * Description: 路由配置文件，auth判断页面是否需要登录才能访问，power访问该页面需要什么权限
 * Date: 2022/6/29 10:57
 * Update: 2022/6/29 10:57
 */
 import {useRoutes,Route} from "react-router-dom";
 import { Suspense, lazy } from 'react'
 import AnthRouter from '../components/AnthRouter'
import Rewards from "../page/activity/rewards";
import Ranking from "../page/activity/ranking";

 const routes = [
     {
        path: '/',
        auth:false, //是否需要进行登录
        component:lazy(() => import('@/page/MailPage'))
     },
     {
        path: '/mail',
        auth:false, //是否需要进行登录
        component:lazy(() => import('@/page/MailPage'))
     },
     {
         path: '/ranking',
         auth:false, //是否需要进行登录
         component:lazy(() => import('@/page/Ranking'))
     },
     {
         path: '/notice',
         auth:false, //是否需要进行登录
         component:lazy(() => import('@/page/Notice'))
     },
     {
         path: '/assignment',
         auth:false, //是否需要进行登录
         component:lazy(() => import('@/page/Assignment'))
     },
     {
         path: '/assignment_1',
         auth:false, //是否需要进行登录
         component:lazy(() => import('@/page/Assignment_1'))
     },
     {
         path: '/award',
         auth:false, //是否需要进行登录
         component:lazy(() => import('@/page/Award'))
     },
     {
         path: '/privacypolicy',
         auth:false, //是否需要进行登录
         component:lazy(() => import('@/page/Privacypolicy'))
     },
     {
         path: '/termOfService',
         auth:false, //是否需要进行登录
         component:lazy(() => import('@/page/TermOfService'))
     },
     //活动相关页面
     {
         path: '/activity/wait',
         auth:false, //是否需要进行登录
         component:lazy(() => import('@/page/activity/wait'))
     },
     // {
     //     path: '/activity/home',
     //     auth:false, //是否需要进行登录
     //     component:lazy(() => import('@/page/activity/home'))
     // },
     // {
     //     path: '/activity/novice',
     //     auth:false, //是否需要进行登录
     //     component:lazy(() => import('@/page/activity/novice'))
     // },
     // {
     //     path: '/activity/dailyTasks',
     //     auth:false, //是否需要进行登录
     //     component:lazy(() => import('@/page/activity/dailyTasks'))
     // },
     // {
     //     path: '/activity/rewards',
     //     auth:false, //是否需要进行登录
     //     component:lazy(() => import('@/page/activity/rewards'))
     // },
     // {
     //     path: '/activity/record',
     //     auth:false, //是否需要进行登录
     //     component:lazy(() => import('@/page/activity/record'))
     // },
     // {
     //     path: '/activity/ranking',
     //     auth:false, //是否需要进行登录
     //     component:lazy(() => import('@/page/activity/ranking'))
     // },
     // 帮助页面
     // {
     //     path: '/help/home',
     //     auth:false, //是否需要进行登录
     //     component:lazy(() => import('@/page/helpPage/home'))
     // },
     {
         path: '/help',
         auth:false, //是否需要进行登录
         component:lazy(() => import('@/page/helpPage/home')),
         children: [
             {
                 path: '/help',
                 auth:false, //是否需要进行登录
                 component:lazy(() => import('@/page/helpPage/tutorial'))
             },
             {
                 path: '/help/tutorial',
                 auth:false, //是否需要进行登录
                 component:lazy(() => import('@/page/helpPage/tutorial'))
             },
             {
                 path: '/help/question',
                 auth:false, //是否需要进行登录
                 component:lazy(() => import('@/page/helpPage/question'))
             },
             {
                 path: '/help/support',
                 auth:false, //是否需要进行登录
                 component:lazy(() => import('@/page/helpPage/support'))
             },
             // {
             //     path: '/help/email',
             //     auth:false, //是否需要进行登录
             //     component:lazy(() => import('@/page/helpPage/email'))
             // },
             {
                path: '/help/feedback',
                auth:false, //是否需要进行登录
                component:lazy(() => import('@/page/helpPage/feedback'))
            }
         ]
     },
     // 运营活动第二版
     {
         path: '/activity/home',
         auth:false, //是否需要进行登录
         component:lazy(() => import('@/page/activity_1/home'))
     },
     {
         path: '/activity/novice',
         auth:false, //是否需要进行登录
         component:lazy(() => import('@/page/activity_1/novice'))
     },
     {
         path: '/activity/dailyTasks',
         auth:false, //是否需要进行登录
         component:lazy(() => import('@/page/activity_1/dailyTasks'))
     },
     {
         path: '/activity/invite',
         auth:false, //是否需要进行登录
         component:lazy(() => import('@/page/activity_1/invite'))
     },
     {
         path: '/activity/ranking',
         auth:false, //是否需要进行登录
         component:lazy(() => import('@/page/activity_1/ranking'))
     },
     {
         path: '/activity/contest',
         auth:false, //是否需要进行登录
         component:lazy(() => import('@/page/activity_1/contest'))
     },
    {
        path: '/authCard',
        auth:false, //是否需要进行登录
        component:lazy(() => import('@/page/authCard'))
    },
    {
         path: '/publicize',
         auth:false, //是否需要进行登录
         component:lazy(() => import('@/page/publicize_1'))
    },
    {
        path: '/noviciateVoid',
        auth:false, //是否需要进行登录
        component:lazy(() => import('@/page/noviciateVoid'))
    }
 ]

 //根据路径获取路由
 const checkAuth = (routers, path)=>{
     for (const data of routers) {
         if (data.path===path) return data
         if (data.children) {
             const res = checkAuth(data.children, path)
             if (res) return res
         }
     }
     return null
 }

 // 路由处理方式
 const generateRouter = (routers) => {
     return routers.map((item) => {
         if (item.children) {
             item.children = generateRouter(item.children)
         }
         item.element = <Suspense fallback={
             <div>Loading...</div>
         }>
             {/* 把懒加载的异步路由变成组件装载进去 */}
             <AnthRouter power={item.power || ''} auth={item.auth} element={item.component}></AnthRouter>
         </Suspense>
         return item
     })
 }

 const Router = () => useRoutes(generateRouter(routes))

 const checkRouterAuth = (path)=>{
     let auth = null
     auth = checkAuth(routes,path)
     return auth
 }
 export{ Router,checkRouterAuth}
