/**
 * @name: index
 * @author: LJJ
 * @date: 2023-03-28 15:28
 * @description：排行榜页面
 * @update: 2023-03-28 15:28
 */
import style from './index.module.scss'
import back from "@/assets/activity/novice/back.png"
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import question_mark from "@/assets/activity/question_mark.png"
import stars from "@/assets/activity/dailyTasks/stars.png"
import ActivityMarkCard from "@/components/ActivityMarkCard";
import {_getUserRank} from "@/api/activity"
import gift_packs from "@/assets/activity/ranking/gift_packs.png"
import Prizes from "./prizes";
import light from "@/assets/activity/ranking/light.png"
import LoadingMask from '@/components/LoadingMask'
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc)
dayjs.extend(timezone)
export default function Ranking(){

    const navigate = useNavigate();

    const toHome = () => {
        navigate(-1)
    }

    //每日任务规则弹窗显示控制
    const [getRule,setGetRule] = useState(false)

    //获取排行榜列表
    const [dataList,setDataList] = useState([])
    const [loading,setLoading] = useState(false);
    const getUserRank = ()=>{
        setLoading(true)
        _getUserRank(value=>{
            setDataList(value);
            setLoading(false);
        })
    }

    useEffect(()=>{
        getUserRank();
    },[])

    //计算多少颗星
    const getStars = (number)=>{

        if (number>=80){
            number = 5;
        }else if (number>=60){
            number = 4
        }else if (number>=40){
            number = 3
        }else if (number>=20){
            number = 2
        }else if (number>=5){
            number = 1
        }else {
            return "null";
        }
        let arr = [];
        for (let i=0;i<number;i++){
            arr.push("1")
        }
        return arr;
    }

    //倒计时方法

    const getTime = ()=>{

    }

    //获取截止时间的时间戳（单位毫秒）
    let inputTime = null;

    const [timeInfo,setTimeInfo] = useState(null);
    let angle = 0;
    const getPart = ()=> {
        //获取当前时间戳并换算为秒做单位
        const current = Math.floor(new Date().getTime() / 1000);
        console.log("current",current)
        console.log("inputTime",inputTime)
        //时间戳相差多少秒
        let diff = inputTime - current
        //判断是否过时
        let _diffData = null;
        if (diff > 0) {
            let d = Math.floor(diff / (3600 * 24))        //获取天数
            let h = Math.floor((diff % (3600 * 24)) / (60 * 60))        //获取时
            let m = Math.floor(((diff % (3600 * 24)) % (60 * 60)) / 60)        //获取分
            let s = Math.floor(diff % 60)        //获取秒
            h = h + d * 24;
            d = d < 10 ? "0" + d : d;
            h = h < 10 ? "0" + h : h;
            m = m < 10 ? "0" + m : m;
            s = s < 10 ? "0" + s : s;
            _diffData = [d, h, m, s]
        } else {
            _diffData = ["00", "00", "00", "00"];
        }
        setTimeInfo(_diffData)
        if (document.getElementsByClassName("back_light")[0]){
            angle = angle + 10;
            document.getElementsByClassName("back_light")[0].style = "transform: translate(-50%,-50%) rotate("+ angle +"deg)"
        }
    }

    useEffect(()=>{
        angle = 0;
        let time = 1700047800000;

        inputTime = time / 1000;
        //定时器 每隔一秒变化一次
        getPart();
        let timer = setInterval(getPart, 1000);
        return ()=>{
            window.clearInterval(timer);
        }
    },[])

    const [prizes,setPrizes] = useState(false);

    return (
        <div className={style.ranking}>
            <div className={"back_card"}>
                <img src={back} alt="" onClick={toHome}/>
                <div className={"back_text"}>
                    Leaderboard
                    <img src={question_mark} alt="排行榜页面-查看规则按钮" onClick={()=>{setGetRule(true)}}/>
                </div>
            </div>
            <div className={"record_card"}>
                <div className={"record_card_right"}>
                    <div className={"right_body"}>
                        <div className={"img_card"} >
                            <img src={light} className={"back_light"} style={{"transform": "translate(-50%,-50%) rotate(0deg)", pointerEvents: "none"}}/>
                            <img className={"gift_image"} src={gift_packs} alt="" onClick={()=>{setPrizes(true)}} alt="排行榜页面-礼物展示"/>
                        </div>
                        <div className={"record_tip"}>
                            {/*<div className={"title_h1"}>Map Hero Bangalore </div>*/}
                            <div className={"title_time_card"}>
                                <div className={"label"}>Countdown</div>
                                <div className={"time_card"}>
                                    {
                                        timeInfo?
                                            <div className={"time_label"}>
                                                <div>{timeInfo[1]}</div>
                                                <div>:</div>
                                                <div>{timeInfo[2]}</div>
                                                <div>:</div>
                                                <div>{timeInfo[3]}</div>
                                            </div>
                                            :
                                            null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"record_card_left"}>
                    <div className={"text"}>Thank you for contributing to a better map</div>
                    <div className={"record_table"}>
                        <div className={"table_header"}>
                            <div style={{"width": "45px","textAlign": "center"}}>
                                <div>Ranking</div>
                            </div>
                            <div style={{"width": "61px"}}>
                                <div>Name</div>
                            </div>
                            <div style={{"width": "94px","textAlign": "center"}}>
                                <div>Coins  Collected</div>
                            </div>
                            {/*<div style={{"width": "90px"}}>*/}
                            {/*    <div>Quality</div>*/}
                            {/*</div>*/}
                            <div style={{"width": "107px","textAlign": "center"}}>
                                <div>Earnings</div>
                            </div>
                        </div>
                        <div className={"table_body"}>
                            {
                                dataList.map(item=>{
                                    return (
                                        <div className={"table_body_item"} key={item.rankNUm}>
                                            <div style={{"width": "45px","textAlign": "center"}}>
                                                <div>{item.rankNum}</div>
                                            </div>
                                            <div style={{
                                                "width": "61px",
                                            }}>
                                                <div style={{
                                                    "width": "100%",
                                                    "overflow": "hidden",
                                                    "text-overflow": "ellipsis",
                                                    "white-space": "nowrap"
                                                }}>{item.userName}</div>
                                            </div>
                                            <div style={{"width": "94px","textAlign": "center"}}>
                                                <div>{item.taskNum}</div>
                                            </div>
                                            {/*<div style={{"width": "90px"}}>*/}
                                            {/*    <div>*/}
                                            {/*        {*/}
                                            {/*            Array.isArray(getStars(item.passRate))?*/}
                                            {/*                getStars(item.passRate).map((item,index)=>{*/}
                                            {/*                return <img src={stars} alt="" key={index}/>*/}
                                            {/*            })*/}
                                            {/*            :*/}
                                            {/*            getStars(item.passRate)*/}
                                            {/*        }*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            <div style={{"width": "107px","textAlign": "center"}}>
                                                <div>{ item.rewardAmount }</div>
                                            </div>
                                        </div>
                                    )
                                })

                            }
                        </div>
                    </div>
                </div>
            </div>
            {
                getRule?
                    <ActivityMarkCard title={"Rules"} close={()=>{setGetRule(false)}}>
                        <div>
                            <p>1. The Map Hero event is ongoing, do map tasks and win leaderboard rewards ! </p>
                            <p>2. Throughout the event, the victor will be the participant with the most Go Points and completed tasks with quality <img src={stars} alt=""/> rating or above.</p>
                            <p>3. Prizes:<br/>
                                <div style={{"text-indent": "2em"}}>
                                    <div>1st Place -   1 smartphone worth 17000rs</div>
                                    <div>2nd Place - 1 prize worth 4500 rs;</div>
                                    <div>3rd Place - 1 prize worth 2000 rs;</div>
                                    <div>Plus, 4th to 15th Place (12 users in total) - 1 prize worth 500 rs each.</div>
                                </div>
                            </p>
                            <p>4. To qualify for the First Prize, user should complete no less than 8,000 coins cumulatively.</p>
                            <p>5. Please note that any attempts at data manipulation, cheating, or exploiting application vulnerabilities will be disqualified.</p>
                            <p>6. The Mapr team reserves the right to the final interpretation of this event.</p>
                        </div>
                    </ActivityMarkCard>
                    :
                    null
            }
            {
                prizes?
                    <Prizes close={()=>{setPrizes(false)}}></Prizes>
                    :
                    null
            }
            <LoadingMask show={loading}></LoadingMask>
        </div>
    )
}
