/**
 * Create by 14059
 * Description: 权限检测，判断是否登录，和是否具有权限
 * Date: 2022/6/29 10:57
 * Update: 2022/6/29 10:57
 */
//  import store from '@/store'
//  import {haveToken} from "@/utils/Token";
 import { useNavigate,useLocation } from "react-router-dom"
 import {useEffect, useState} from "react"
import {routerChange} from  '@/util/Tool'

 export default function AnthRouter(props) {

     const {element,power,auth} = props
     const Element = element;
     const navigate = useNavigate();
     const location = useLocation();

     useEffect(()=>{
         routerChange();
        //  判断是否登录，没有则跳转至登录页面
         if(auth){
             navigate('/')
         }else {
             //已经登录过，则存在power权限。进行权限验证,权限验证未通过则跳转至权限提醒页面
             if ( power ){
                 let arr = location.pathname.split('/');
                 arr[arr.length-1] = "nopower";
                 navigate(arr.join('/'))
             }
         }
     })

     return (
         <Element></Element>
     )
 }
