/**
 * @name: index
 * @author: LJJ
 * @date: 2023-08-16 13:39
 * @description：活动提示框组件
 * @update: 2023-08-16 13:39
 */
import style from './index.module.scss'
import closure from '@/assets/activity/closure2.png'
export default function ActivityMarkCard({title="",children=null,close=()=>{}}){

    const toClose = ()=>{
        close()
    }

    return (
        <div className={style.activityMarkCard}>
            <div className={"activity_card_body"}>
                <div className={"card_body_header"}>
                    {title}
                    <img src={closure} alt="" onClick={toClose}/>
                </div>
                <div className={"card_body_content"}>
                    {children}
                </div>
            </div>
        </div>
    )
}
