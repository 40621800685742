// 设置 rem 函数
function setRem(num) {
    // 1920 默认大小16px; 1920px = 120rem ;每个元素px基础上/16
    const screenWidth = 812 //修改为设计稿的大小
    const scale = screenWidth / 16
    const htmlWidth = num;
    // 得到html的Dom元素
    const htmlDom = document.getElementsByTagName('html')[0]
    // 设置根元素字体大小
    htmlDom.style.fontSize = htmlWidth / scale + 'px'
  }
  // 初始化
  hengshuping();
  // // 改变窗口大小时重新设置 rem
  // window.onresize = function() {
  //   hengshuping();
  // }

//判断手机横竖屏状态：
function hengshuping(){
  if(window.orientation==180||window.orientation==0){
    let height = document.documentElement.clientHeight || document.body.clientHeight;
    setRem(height);
  }
  if(window.orientation==90||window.orientation==-90){
    let width = document.documentElement.clientWidth || document.body.clientWidth;
    setRem(width);
  }
}
// window.addEventListener("onorientationchange" in window ? "orientationchange" : "resize", hengshuping, false);
