/**
 * @name: record
 * @author: LJJ
 * @date: 2023-09-04 17:24
 * @description：埋点记录
 * @update: 2023-09-04 17:24
 */
import { _addActivityLog } from '../api/record'
(function (){
    document.body.addEventListener("click",e=>{
        if (typeof(e.target.getAttribute("alt")) != "undefined" && e.target.getAttribute("alt")!=null && e.target.getAttribute("alt")!=""){
            _addActivityLog({
                operationTime: new Date().getTime(),
                operationType: e.target.getAttribute("alt")
            },()=>{})
        }
    })
})()
