/**
 * @name: index
 * @author: LJJ
 * @date: 2023-08-29 14:08
 * @description：加载遮罩层
 * @update: 2023-08-29 14:08
 */
import style from './index.module.scss'

export default function LoadingMask({show=false}){

    return (
        <>
        {
            show?
                <div className={style.loadingMask}>
                    <div className={"folding_card"}>
                        <div className="folding">
                            <div className="sk-cube1 sk-cube"></div>
                            <div className="sk-cube2 sk-cube"></div>
                            <div className="sk-cube4 sk-cube"></div>
                            <div className="sk-cube3 sk-cube"></div>
                        </div>
                    </div>
                </div>
                :
                null
        }
        </>
    )
}
