/**
 * @name: index
 * @author: LJJ
 * @date: 2022-11-24 09:57
 * @description：提示弹层
 * @update: 2022-11-24 09:57
 */
import style from './index.module.scss'
import { unmountComponentAtNode, render } from "react-dom";
import hint from '@/assets/icon/hint.png'
import hint_full from '@/assets/icon/hint_full.png'
import close_img from '../../assets/icon/closure.png'
import {useEffect} from "react";
import toast from "@/assets/image/toast.png"

function Message({type="delete",onClose,onconfirm=()=>{},msg=""}){

    const onconfirmCilck = ()=>{
        onClose();
        onconfirm();
    }

    useEffect(()=>{
        setTimeout(()=>{
            onClose();
        },2000)
    },[])

    return (
        // <div className={style.main} style={type=="delete"?{background: "linear-gradient(180deg,#0a0600, #291800 100%)"}:{background: "linear-gradient(180deg,#000a07, #00221a 100%)"}}>
        <div className={style.main}>
        {/*<div className={"card"}>*/}
            {/*    <div className={"card_title_card"}>*/}
            {/*        <div className={"card_title"}>*/}
            {/*            <span style={type=="delete"?{textShadow: "0px 0px 8px #ff9500"}:{textShadow: "text-shadow: 0px 0px 8px #00ffc6; "}}>TOAST</span>*/}
            {/*            <div className={"card_title_back"} style={type=="delete"?{background: "radial-gradient(rgba(255,149,0,0.00), #ff9500, rgba(255,149,0,0.00))"}:{background: "radial-gradient(rgba(0,255,198,0.00), #00ffc6, rgba(0,255,198,0.00))"}}></div>*/}
            {/*        </div>*/}
            {/*        <div className={"close_icon"} onClick={onClose}>*/}
            {/*            <img src={close_img} alt=""/>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div className={type=="delete"?"message_card message_card_warning":"message_card message_card_full"}>*/}
            {/*        {*/}
            {/*            msg==""?*/}
            {/*                type=="delete"?*/}
            {/*                    "Are you sure you want to delete the current message?"*/}
            {/*                    :*/}
            {/*                    "Your mail is full and cannot receive new mail, please clear it in time"*/}
            {/*                :*/}
            {/*                msg*/}
            {/*        }*/}
            {/*    </div>*/}
            {/*    <div className={type=="delete"?"button_card button_card_warning":"button_card button_card_full"} onClick={onconfirmCilck}>*/}
            {/*        Sure*/}
            {/*    </div>*/}
            {/*</div>*/}
            <img className={"img_back"} src={toast} alt=""/>
            <div className={"toast_text"}>{msg}</div>
        </div>
    )
}

const getContainer = () => {
    const container = document.querySelector('#customMessageWrapper')
    if ( !container ) {
        const _container = document.createElement('div')
        _container.id = 'customMessageWrapper'
        _container.className = `fixed flex flex-col gap-4 top-0 items-center py-5 left-1/2 -translate-x-1/2`
        document.body.appendChild(_container)
        return _container
    }
    return container
}

//公用message方法
const _message = (type) => (props) => {
    const container = getContainer();
    const _dom = document.createElement("div");

    container.appendChild(_dom);

    const hanldeClose = () => {
        unmountComponentAtNode(_dom);
        container.removeChild(_dom);
    };

    render(
        <Message
            {...props}
            type={type}
            onClose={hanldeClose}
        />,
        _dom
    );
};

const del = _message("delete");
const full = _message("full");
const clean = _message("clean");

export default {
    del,
    full,
    clean
};
