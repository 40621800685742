import axios  from 'axios'
// import axiosRetry from 'axios-retry';

import { getCookie } from '@/util/Cookie'


const request = axios.create({
    baseURL: window.RequestUrl,
    timeout: 10000,
    headers: {
        "Accept": "application/vnd.DaoY.v1+json",
        "Access-Control-Allow-Origin": "*"
    }
})


// 添加请求拦截器
request.interceptors.request.use(function (config) {
    // 对请求进行拦截，添加token,放开登录和系统配置请求接口
    // config.headers.token = getToken();
    config.headers.Authorization = getCookie("token");
    // 在发送请求之前做些什么
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
request.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    if (response.data.code == 500){
        //响应服务发生错误
        // setTimeout(()=>{
        //     window.location.href="/login"
        // },500)
    }
    return response.data;
}, function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
});

// axiosRetry(request, { retries: 3 })

export default request;
