/**
 * @name: Token
 * @author: LJJ
 * @date: 2022-12-02 10:12
 * @description：Token
 * @update: 2022-12-02 10:12
 */
import Message from "@/components/Message"
/**
 * 获取移动端传的token
 * @returns {Token}
 */
export function callAndroid(){
    let token = window.Android.getToken();
    return token;
}

/**
 * 获取手机电量
 * @returns {*}
 */
export function getBattery(){
    return window.Android.getBattery();
}

/**
 * 获取gps信号
 * @returns {*}
 */
export function getGpsSignal(){
    return window.Android.getGpsSignal();
}

/**
 * 获取网络信号
 * @returns {*}
 */
export function getNetSignal(){
    return window.Android.getNetSignal();
}

/**
 * 跳转至app的扫描功能
 */
export function toAppScan(){
    // let gps = getGpsSignal().split(",");
    // let type = gps[0];
    // let num = gps[1];
    // if (type == 0 || num >20){
    //     Message.full({
    //         msg: "Weak GPS signal. Please move outdoors and then start the scanning.",
    //     })
    //     return;
    // }
    //跳转到app的扫描功能，并关闭内嵌浏览器
    window.Android.toAppScan();
}

/**
 * 调用app截图功能
 */
export function screenshot(){
    if(window.Android.screenshot()){
        Message.del({
            msg: "Success!"
        })
    }else {
        Message.full({
            msg: "Screenshot failed!"
        })
    }
}

/**
 * 关闭app内嵌浏览器
 */
export function closeH5(){
    console.log("关闭H5")
    window.Android.closeH5();
    console.log("关闭H5---")
}

/**
 * 调取安卓第三方浏览器
 */
export function wakeUpBrowser(url=""){
    window.Android.wakeUpBrowser(url);
}

/**
 * 跳转到app的钱包
 */
export function toEarning(){
    try {
        window.Android.toEarning();
    }catch (e) {
        Message.full({
            msg: "The current version is too old, please update to the latest version!"
        })
    }
}

/**
 * 跳转到用户中心的邀请码页面方法
 */
export function toRefer(){
    try {
        window.Android.toRefer();
    }catch (e) {
        Message.full({
            msg: "The current version is too old, please update to the latest version!"
        })
    }
}

/**
 * 跳转到帮助页面方法
 */
export function toHelp(){
    window.Android.toHelp();
}
