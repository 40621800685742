/**
 * @name: Cookie
 * @author: LJJ
 * @date: 2022-12-01 09:30
 * @description：Cookie
 * @update: 2022-12-01 09:30
 */
export function setCookie(cname,cvalue)
{
    // let d = new Date();
    // d.setTime(d.getTime()+(exdays*24*60*60*1000));
    // let expires = "expires="+d.toGMTString();
    document.cookie = cname + "=" + cvalue + "; ";
}

export function getCookie(cname)
{
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for(var i=0; i<ca.length; i++)
    {
        var c = ca[i].trim();
        if (c.indexOf(name)==0) return c.substring(name.length,c.length);
    }
    return "";
}